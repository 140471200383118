import { SliceZone } from '@prismicio/react';
import { useRouter } from 'next/compat/router';
import { useMemo } from 'react';

import { getPageComponents } from '@virginexperiencedays/cms/constants/slices';
import { ByPassBlock } from '@virginexperiencedays/components-v2/src/ByPassBlock';
import { Stack } from '@virginexperiencedays/components-v2/src/layout/Stack';
import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import { DyContextPageType } from '@virginexperiencedays/dy';
import { submit as onNewsletterSubmit } from '../../../utils/newsletter';
import DynamicYield from '../../DynamicYield/DynamicYield';
import { getPageMetaData } from '../../SEO/utils/metadata';
import { PAGE_TYPES } from '../../tracking/gtm/consts';
import { useIsProductCardsV2 } from '../search/ProductCard';
import { bind as dangerouslyBindClickTracking } from '../search/tracking/clicks';
import { slice as trackSlice } from '../search/tracking/interactions';
import HomePageSEO, { type HomePageSEOType } from './HomePageSEO';
import { useHomePageTracking } from './tracking/useHomePageTracking';

import type { RTHeading1Node, RichTextField } from '@prismicio/types';

import type { BasePageProps as PageProps } from '../types';

const Page = ({ isDarkTheme, prismicPage, prismicSlices, slug, listOfLinks }: PageProps) => {
  const isProductCardsV2 = useIsProductCardsV2();
  const router = useRouter();
  const pageTitle: RichTextField = prismicPage?.data?.page_title ?? null;
  const seo: HomePageSEOType = getPageMetaData(prismicPage?.data);

  const isSlicesExisting = !!prismicSlices?.length;
  const pageTitleObject =
    Array.isArray(pageTitle) && pageTitle.length && (pageTitle?.[0] as RTHeading1Node);
  const mainContentId = 'home-main-content';
  const pageType = PAGE_TYPES.Home;

  const pageComponents = useMemo(() => getPageComponents(), []);

  useHomePageTracking({ slices: prismicSlices });
  prismicSlices = dangerouslyBindClickTracking({
    slices: prismicSlices,
    slug: 'home',
    next: (url) => router?.push(url),
    hasGAEvent: false,
    pageType,
    isProductCardsV2,
  });

  return (
    <>
      <HomePageSEO {...seo} />
      <DynamicYield type={DyContextPageType.HOMEPAGE} />
      <ByPassBlock mainContentId={mainContentId} />
      {isSlicesExisting && (
        <Stack>
          <SliceZone
            slices={prismicSlices}
            components={pageComponents}
            context={{
              router,
              slug: slug === '/' ? 'home' : slug,
              lastSlug: 'home',
              hide: false,
              pageTitle: pageTitleObject?.text ?? '',
              pageType,
              listOfLinks,
              isDarkTheme,
              isProductCardsV2,
              trackSlice,
              onNewsletterSubmit,
              overridesSlugInfo: null,
              getPrismicLinkPath,
              mainContentId,
              pageDataTitle: pageTitle,
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default Page;
